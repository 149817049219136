import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { GetLocationLanguageSelectionFeatureFlag } from './__generated__/GetLocationLanguageSelectionFeatureFlag';

type FeatureFlagOutput = {
  enabled: boolean;
};

const GET_LANGUAGE_SELECTION_ENABLED_FEATURE_FLAG = gql`
  query GetLocationLanguageSelectionFeatureFlag {
    isFeatureFlagEnabledForDeviceOrg(flagType: LANGUAGE_SELECTION_ENABLED)
  }
`;

export const useLocationLanguageSelectionFeatureFlag: () => FeatureFlagOutput =
  () => {
    const { data } = useQuery<GetLocationLanguageSelectionFeatureFlag>(
      GET_LANGUAGE_SELECTION_ENABLED_FEATURE_FLAG
    );

    return {
      enabled: data?.isFeatureFlagEnabledForDeviceOrg ?? false,
    };
  };
